import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { SesionService } from '../sesion/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public router: Router,
    private sesion: SesionService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const expectedRoles: any[] = route.data['expectedRoles'];
    const rol = this.sesion.rol;
    const token = this.sesion.token;

    if (!expectedRoles || expectedRoles.length == 0) {
      return true;
    }

    if (expectedRoles.includes(rol) && token) {
      return true;
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }

}
