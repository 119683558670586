import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { UsuarioPassChangeComponent } from './components/usuario/usuario-pass-change/usuario-pass-change.component';
import { UsuarioProfileComponent } from './components/usuario/usuario-profile/usuario-profile.component';
import { SesionService } from './utils/sesion/sesion.service';
import data from './utils/sesion/routes.json'
import { ProductoService } from './components/producto/producto.service';
import { SucursalService } from './components/sucursal/sucursal.service';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { VersionUpdateComponent } from './utils/version-update/version-update.component';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  routes: any[] = [];

  index: any = {
    "page": 1,
    "sorted_asc": true,
    "sorted": false,
    "sorted_by": null,
    "filtered": false,
    "filters": [],
    "pageSize": 20
  };
  stockCriticos: Array<any> = [];

  constructor(
    public sesion: SesionService,
    private router: Router,
    private loading: LoadingService,
    private alerts: AlertService,
    private modalService: NgbModal,
    private productoService: ProductoService,
    private ngSelectConfig: NgSelectConfig,
    private sucursalService: SucursalService,
    private mdService: MarkdownService,
  ) {
    Chart.register(ChartDataLabels);

    this.ngSelectConfig.notFoundText = 'Ningún elemento encontrado.';
    // this.ngSelectConfig.appendTo = 'body';
  }

  ngOnInit() {
    // this.routes = data[0].routes;
    this.loadRoutes();
    this.isLogged();
    this.getSucursales();
    this.cargarStockCritico();
  }

  loadRoutes() {
    this.sesion.rutas$.subscribe((res: any) => {
      this.routes = res;
    })
  }

  cargarVersionUpdate(){
    this.sesion.getLatestVersion().subscribe({
      next: (version: any) => {
        if(!version) return;
        if(localStorage.getItem('latest-version') != version) {
          localStorage.setItem('latest-version', version);
          this.sesion.getLatestVersionMd().subscribe({
            next: (resMD: any) => {
              let md = this.mdService.compile(resMD);
              const modalRef = this.modalService.open(VersionUpdateComponent,{ size: 'md', scrollable: true, windowClass: 'modal-version-update' });
              modalRef.componentInstance.md = md;
              modalRef.componentInstance.version = version;
              modalRef.result.then((result: any) => {
                location.reload();
              }, (reason: any) => {
                location.reload();
              });
            }, error: (err: any) => {}
          });
        }
      }, error: (err: any) => {
      }
    });
  }

  changePass() {
    let modalRef = this.modalService.open(UsuarioPassChangeComponent, { centered: true });
    modalRef.result.then((result) => { }, (reason) => { })
  }

  verPerfil() {
    const modalRef = this.modalService.open(UsuarioProfileComponent, { windowClass: 'clear-modal', size: 'lg', scrollable: true });
    modalRef.componentInstance.element = this.sesion.user;
    modalRef.result.then((result) => { }, (reason) => { });
  }

  logout() {
    this.loading.show();
    this.sesion.clearSesion();
    this.loading.hide();
    this.router.navigate(['login']);
  }

  searchFn(term: string, item: any) {
    const removeAccents = (str: string) => {
      return str.normalize("NFD").replace(/[̀-ͯ]/g, "");
    }
    let terms = removeAccents(term.toLowerCase()).split(' ');
    let result = true;
    let p = removeAccents(([item.nombre].join(' ')).toLowerCase());
    terms.forEach(t => {
      result = result && p.includes(t);
    });
    return result;
  }

  isLogged(): void {
    if (window.location.pathname === '/terminos') return;
    if (window.location.pathname === '/soporte') return;
    if (window.location.pathname === '/recuperar-password') return;
    if (window.location.pathname.includes('/restablecer')) return;
    if (this.sesion.token) {
      this.loading.show();
      this.sesion.isLogged().subscribe({
        next: (res) => {
          this.loading.hide();
          localStorage.setItem('id', res.usuario.id);
          localStorage.setItem('token', res.token);
          localStorage.setItem('usuario', JSON.stringify(res.usuario));
          this.sesion.setRutas(data[res.usuario.rol_id].routes);
          // localStorage.setItem('sucursal_id', "");
          this.getSucursales();
          this.cargarVersionUpdate();
        },
        error: (err) => {
          console.error(err);
          this.sesion.clearSesion();
          this.router.navigate(['login']);
          this.loading.hide();
          this.alerts.addAlert("Sesión expirada.", "danger");
        }
      })
    } else {
      this.router.navigate(['login']);
    }
  }

  get showSidebarAndHeader(): boolean {
    if (this.router.url === '/login') return false;
    if (this.router.url === '/terminos') return false;
    if (this.router.url === '/soporte') return false;
    if (this.router.url === '/recuperar-password') return false;
    if (this.router.url.includes('/restablecer')) return false;
    return true;
  }

  verStockCritico(){
    this.router.navigate(['producto/stock-critico']);
  }

  getSucursales(): void {
    if (!this.sesion.token) return;
    this.sucursalService.misSucursales().subscribe({
      next: (res) => {
        this.sesion.sucursales = res.sucursales;
        if (this.sesion.sucursales.length > 0) {
          let localSucursalID = this.sesion.loadLocalSucursal();
          if (localSucursalID) {
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == localSucursalID);
            if (localSucursalID != -1) {
              this.sesion.sucursal = this.sesion.sucursales[this.sesion.sucursalIndex];
            } else {
              this.sesion.sucursal = this.sesion.sucursales[0];
              this.sesion.saveSucursal(this.sesion.sucursal.id);
            }
            return;
          }
          if (this.sesion.sucursal?.id != this.sesion.sucursales[0].id) {
            localStorage.setItem('sucursal', JSON.stringify(this.sesion.sucursales[0]));
            this.sesion.sucursal = this.sesion.sucursales[0];
            let sucursal = this.sesion.sucursal;
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == sucursal.id);
            this.sesion.saveSucursal(this.sesion.sucursal.id);
          } else {
            let sucursal = this.sesion.sucursal;
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == sucursal.id);
          }
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  changeScope(ev: any): void {
    if (!ev.target.value) return;
    let s = this.sesion.sucursales[ev.target.value];
    localStorage.setItem('sucursal', JSON.stringify(s));
    this.sesion.sucursal = s;
    if (this.sesion.sucursal) {
      this.sesion.saveSucursal(this.sesion.sucursal.id);
    }
    let aux = this.router.url;
    this.router.navigate(['loading']);
    setTimeout(() => {
      this.router.navigate([aux]);
    }, 0);
  }

  cargarStockCritico(): void {
    this.loading.show();
    this.productoService.stockCritico(this.index).subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.stockCriticos = res.elements;
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        if (err.response) this.alerts.addAlert(err.response, 'danger');
      }
    });
  }
}
